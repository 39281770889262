import { useTranslation } from "react-i18next";
import testimonialImg1 from './../../images/about/Anastasia.jpg';
import testimonialImg2 from './../../images/about/Roderick.jpg';


const useAboutTestimonials = () => {
    const { t } = useTranslation("common");
    const testimonials = [
        {
            imgSrc: testimonialImg1,
            imgAlt: t('about.testimonials-1-img-alt'),
            authorName: t('about.testimonials-1-authorName'),
            authorRole: t('about.testimonials-1-authorRole'),
            text: t('about.testimonials-1-text')
        },
        {
            imgSrc: testimonialImg2,
            imgAlt: t('about.testimonials-2-img-alt'),
            authorName: t('about.testimonials-2-authorName'),
            authorRole: t('about.testimonials-2-authorRole'),
            text: t('about.testimonials-2-text')
        }
    ]

    return testimonials
}

export default useAboutTestimonials;