import { useTranslation } from "react-i18next";
import villaAlmaImg from './../../images/home/villa-alma.jpg';
import villaBohemeImg from './../../images/home/villa-boheme.jpg';
import villaElementsImg from './../../images/home/villa-elements.jpg';
import villaLigeraImg from './../../images/home/villa-ligera.jpg';
import villaSensesImg from './../../images/home/villa-senses.jpg';
import raesborreDomainImg from './../../images/home/raesborre-domain.jpg';
import urbanizacionJardinesImg from './../../images/home/urbanizacion-jardines-de-don-carlos.jpeg';


const useHomeCarouselProperties = () => {
    const { t } = useTranslation("common");
        const slides = [
        {
            imgSrc: villaAlmaImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-1-linkText'),
            linkHref: '/our-properties'
        },
        {
            imgSrc: villaBohemeImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-2-linkText'),
            linkHref: '/our-properties'
        },
        {
            imgSrc: villaElementsImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-3-linkText'),
            linkHref: '/our-properties'
        },
        {
            imgSrc: villaLigeraImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-4-linkText'),
            linkHref: '/our-properties'
        },
        {
            imgSrc: villaSensesImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-5-linkText'),
            linkHref: '/our-properties'
        },
        {
            imgSrc: raesborreDomainImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-6-linkText'),
            linkHref: '/our-properties'
        },
        {
            imgSrc: urbanizacionJardinesImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-7-linkText'),
            linkHref: '/our-properties'
        }
    ]
    
    return slides
}

export default useHomeCarouselProperties;