import './Highlights.scss';

const Highlights = ({ heading2, highlights }) => {
    const highlightsMap = highlights.map((highlight) => 
        <div className="flex">
            <img src={highlight.icon} alt="highlights icon" />
            <h3>{highlight.title}</h3>
            <p>{highlight.text}</p>   
        </div>        
    )
    
    return (
        <div className="container">
            <h2 className="align-center">{heading2}</h2>
            <div className="highlights flex">
                {highlightsMap}
            </div>
        </div>
    )
}

export default Highlights;