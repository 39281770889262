import './Incentive.scss';
import CTA from '../CTA/CTA';

const Incentive = ({ heading2, incentiveCTAText, incentiveCTAHref }) => {
    return (
        <div className="container">
            <div className="incentive flex">
                
                    <h2>{heading2}</h2>
                
                    {(incentiveCTAText && incentiveCTAHref) && <CTA ctaText={incentiveCTAText} ctaHref={incentiveCTAHref} />}

            </div>
        </div>
    )
}

export default Incentive;