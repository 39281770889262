import { useTranslation } from "react-i18next";

const useRentalManagementAccordionServices = () => {
    const { t } = useTranslation("common");
    const accordionItems = [
        {
            title: t('rental-management.accordion-1-item-1-title'),
            text: t('rental-management.accordion-1-item-1-text')
        },
        {
            title: t('rental-management.accordion-1-item-2-title'),
            text: t('rental-management.accordion-1-item-2-text')
        },
        {
            title: t('rental-management.accordion-1-item-3-title'),
            text: t('rental-management.accordion-1-item-3-text')
        },
        {
            title: t('rental-management.accordion-1-item-4-title'),
            text: t('rental-management.accordion-1-item-4-text')
        },
        {
            title: t('rental-management.accordion-1-item-5-title'),
            text: t('rental-management.accordion-1-item-5-text')
        },
        {
            title: t('rental-management.accordion-1-item-6-title'),
            text: t('rental-management.accordion-1-item-6-text')
        },
        {
            title: t('rental-management.accordion-1-item-7-title'),
            text: t('rental-management.accordion-1-item-7-text')
        },
        {
            title: t('rental-management.accordion-1-item-8-title'),
            text: t('rental-management.accordion-1-item-8-text')
        },
        {
            title: t('rental-management.accordion-1-item-9-title'),
            text: t('rental-management.accordion-1-item-9-text')
        },
        {
            title: t('rental-management.accordion-1-item-10-title'),
            text: t('rental-management.accordion-1-item-10-text')
        },
        {
            title: t('rental-management.accordion-1-item-11-title'),
            text: t('rental-management.accordion-1-item-11-text')
        },
    ]

    return accordionItems
}

export default useRentalManagementAccordionServices;