import { useTranslation } from "react-i18next";
import Hero from "../../components/Hero/Hero";
import hero from "./../../static/images/property-management/hero.jpg";
import CTABlock from "../../components/CTABlock/CTABlock";
import usePropertyManagementAccordionServices from "../../static/content/PropertyManagement/PropertyManagementAccordionServices";
import Accordion from "../../components/Accordion/Accordion";
import Highlights from "../../components/Highlights/Highlights";
import useHomeHighlights from "../../static/content/HomeContent/HomeHighlights";
import FormContainer from "../../components/FormContainer/FormContainer";
import usePropertyManagementAccordionFAQs from '../../static/content/PropertyManagement/PropertyManagementAccordionFAQs';
import Incentive from "../../components/Incentive/Incentive";
import Grid from "../../components/Grid/Grid";
import forWhom from './../../static/images/property-management/for-whom.jpg';
import DocumentMeta from "../../components/DocumentMeta/DocumentMeta";

const PropertyManagement = () => {
    DocumentMeta("Property Management Services in Marbella - Premavista", "Premavista provides professional property management services in Marbella, ensuring your property is well-maintained and generating maximum income. We handle all aspects of property care and tenant management.")
    const { t } = useTranslation("common");

    const accordionItemsServices = usePropertyManagementAccordionServices();
    const highlights = useHomeHighlights();
    const accordionItemsFAQs = usePropertyManagementAccordionFAQs();

    return (
        <>
            <Hero heroImg={hero} heading1={t('property-management.h1')} />
            <CTABlock heading2={t('property-management.ctablock-1-h2')} text={t('property-management.ctablock-1-text')} />
            <Grid reverse={true} imgSrc={forWhom} imgAlt={t('property-management.grid-1-img-alt')} heading3={t('property-management.grid-1-h3')} text={t('property-management.grid-1-text')} />
            <Accordion heading2={t('property-management.accordion-1-h2')} accordionItems={accordionItemsServices} />
            <Highlights heading2={t('home.highlights-h2')} highlights={highlights} />
            <FormContainer backgroundImg={hero} heading3={t('property-management.form-h3')} templateID='template_f3eob18' services={true} />
            <Accordion heading2={t('property-management.accordion-2-h2')} accordionItems={accordionItemsFAQs} />
            <Incentive heading2={t('property-management.incentive-1-h2')} incentiveCTAText={t('property-management.incentive-1-ctatext')} incentiveCTAHref='/contact-us' />
        </>
    )
}

export default PropertyManagement;