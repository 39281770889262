import { useTranslation } from "react-i18next";
import highlightImg1 from './../../images/highlights/icon-reliability.svg';
import highlightImg2 from './../../images/highlights/icon-expertise.svg';
import highlightImg3 from './../../images/highlights/icon-solutions.svg';
import highlightImg4 from './../../images/highlights/icon-peace.svg';

const useHomeHighlights = () => {
    const { t } = useTranslation("common");
    const highlights = [
        {
            icon: highlightImg1,
            title: t('home.highlights.highlight1.title'),
            text: t('home.highlights.highlight1.text')
        },
        {
            icon: highlightImg2,
            title: t('home.highlights.highlight2.title'),
            text: t('home.highlights.highlight2.text')
        },
        {
            icon: highlightImg3,
            title: t('home.highlights.highlight3.title'),
            text: t('home.highlights.highlight3.text')
        },
        {
            icon: highlightImg4,
            title: t('home.highlights.highlight4.title'),
            text: t('home.highlights.highlight4.text')
        }
    ]

    return highlights
}

export default useHomeHighlights;