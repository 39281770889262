import { useTranslation } from "react-i18next";

const usePropertyManagementAccordionServices = () => {
    const { t } = useTranslation("common");
    const accordionItems = [
        {
            title: t('property-management.accordion-1-item-1-title'),
            text: t('property-management.accordion-1-item-1-text')
        },
        {
            title: t('property-management.accordion-1-item-2-title'),
            text: t('property-management.accordion-1-item-2-text')
        },
        {
            title: t('property-management.accordion-1-item-3-title'),
            text: t('property-management.accordion-1-item-3-text')
        },
        {
            title: t('property-management.accordion-1-item-4-title'),
            text: t('property-management.accordion-1-item-4-text')
        },
        {
            title: t('property-management.accordion-1-item-5-title'),
            text: t('property-management.accordion-1-item-5-text')
        },
        {
            title: t('property-management.accordion-1-item-6-title'),
            text: t('property-management.accordion-1-item-6-text')
        },
        {
            title: t('property-management.accordion-1-item-7-title'),
            text: t('property-management.accordion-1-item-7-text')
        },
    ]

    return accordionItems
}

export default usePropertyManagementAccordionServices;