import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import './App.css';
import { useTranslation } from "react-i18next";
import Layout from "./pages/Layout/Layout";
import Home from './pages/Home/Home';
import About from "./pages/About/About";
import PropertyManagement from "./pages/PropertyManagement/PropertyManagement";
import RentalManagement from "./pages/RentalManagement/RentalManagement";
import OurProperties from "./pages/OurProperties/OurProperties";
import ContactUs from "./pages/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import LegalNotice from "./pages/LegalNotice/LegalNotice";

export default function App() {
  const { t } = useTranslation("common");
  return (
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="property-management" element={<PropertyManagement />} />
          <Route path="rental-management" element={<RentalManagement />} />
          <Route path="our-properties" element={<OurProperties />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="legal-notice" element={<LegalNotice />} />
        </Route>
      </Routes>
      <Footer slogan={t('footer.slogan')}/>
    </HashRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
