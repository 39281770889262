import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';

const LegalNotice= () => {
    const { t } = useTranslation("common");

    return (
        <div className="container">
            <Trans>
                <h1>{t('legal-notice.h1')}</h1>
                <h3>{t('legal-notice.1-h3')}</h3>
                <p>{t('legal-notice.1-p-1')}</p>
                <h3>{t('legal-notice.2-h3')}</h3>
                <p>{t('legal-notice.2-p-1')}</p>
                <h3>{t('legal-notice.3-h3')}</h3>
                <p>{t('legal-notice.3-p-1')}</p>
                <h3>{t('legal-notice.4-h3')}</h3>
                <p>{t('legal-notice.4-p-1')}</p>
                <h3>{t('legal-notice.5-h3')}</h3>
                <p>{t('legal-notice.5-p-1')}</p>
                <h3>{t('legal-notice.6-h3')}</h3>
                <p>{t('legal-notice.6-p-1')}</p>
            </Trans>
        </div>
    )
}

export default LegalNotice;