import './Carousel.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import CTA from '../CTA/CTA';

import Slider from "react-slick";

const Carousel = ({ heading2, slidesToShow, slides, ctaText, ctaHref, reviews, reviewText, reviewAuthor}) => { 
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        adaptiveHeight: true,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        responsive: [
            {
                breakpoint: 941,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const slidesMap = slides.map((slide) =>
        <div>
            {reviews ? 
                <div className='carousel__slide--review'>
                    <div className='carousel__slide-img'>
                        <img src={slide.imgSrc} alt={slide.imgAlt} />
                    </div>
                    <p className='carousel__text-review'>"{slide.reviewText}"</p>
                    <p className='carousel__author'>{slide.reviewAuthor}</p>
                </div> 
                : 
                <div className='carousel__slide'>
                    <img src={slide.imgSrc} alt={slide.imgAlt} />
                    <Link to={slide.linkHref}>{slide.linkText}</Link>
                </div>
            }            
        </div>
    );

    return (
        <div className='carousel'>
            <div className='container'>
                <h2 className="align-center">{heading2}</h2>
                <Slider {...settings}>
                    {slidesMap}
                </Slider>
                { (ctaText && ctaHref) && <CTA ctaText={ctaText} ctaHref={ctaHref} /> }
            </div>
        </div>
    );
}
export default Carousel;