import './FormContainer.scss';
import { useState, useRef } from 'react';
import EmailForm from '../EmailForm/EmailForm';

const FormContainer = ({ backgroundImg, heading3, templateID, imgSrc, imgAlt, services, contact}) => {
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState();
    const formContainerRef = useRef(null);

    const onEmailSent = () => {
        setEmailSent(true);
    }

    const onError = (error) => {
        setError(error);
    }

    return (
        <div style={backgroundImg ? { backgroundImage: `url(${backgroundImg})`, backgroundRepeat: `no-repeat`, backgroundSize: `cover`, backgroundPosition: `center` } : {}}>
            <div className="container">
                <div ref={formContainerRef} className="form-container flex">
                    <div className="form-box flex flex__col--7">
                        {heading3 && <h3>{heading3}</h3>}
                        {error && <h2 className="contact__error">Sorry, something went wrong. You email hasn't been sent. Please, try again.</h2>}
                        {!emailSent && <EmailForm onEmailSent={onEmailSent} onError={onError} templateID={templateID} isServices={services} formContainerRef={formContainerRef} isContact={contact}/>}
                        {emailSent && <p>Thank you! Your message has been successfully sent. Our team will get back to you within 24 hours.</p>}
                    </div>
                    { (imgSrc && imgAlt) &&
                        <div className="form-image flex flex__col--6">
                            <img src={imgSrc} alt={imgAlt} />
                        </div>
                    } 
                </div>
            </div>
        </div>
    )
}

export default FormContainer;