import './TextBlock.scss';

const TextBlock = ({ heading2, text }) => {
    return (
        <div className="container">
            <h2>{heading2}</h2>
            <p>{text}</p>
        </div>
    )
}

export default TextBlock;