import './EmailForm.scss';
import { useTranslation } from "react-i18next";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const EmailForm = ({ onEmailSent, onError, templateID, formContainerRef, isServices, isContact}) => {
    const { t } = useTranslation("common");

    const [selectedOption, setSelectedOption] = useState('');
    const [otherInputVisible, setOtherInputVisible] = useState(false);
    const [otherInputValue, setOtherInputValue] = useState('');

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        if (value === 'Other') {
            setOtherInputVisible(true);
        } else {
            setOtherInputVisible(false);
        }
    };

    const handleOtherInputChange = (event) => {
        setOtherInputValue(event.target.value);
    };

    const [isOtherChecked, setIsOtherChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsOtherChecked(event.target.checked);
    };

    const [services, setServices] = useState({
        services_1: false,
        services_2: false,
        services_3: false,
        services_4: false,
        services_5: false,
        services_6: false,
        services_7: false,
        services_other: false,
    });

    const handleServicesCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setServices(prevState => ({
            ...prevState,
            [name]: checked
        }));
        setError(null);
    };

    const [value, setValue] = useState()

    const [isLoading, setIsLoading] = useState(false);
    const form = useRef();

    const [error, setError] = useState(null);
    

    const sendEmail = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const checkedServices = Object.values(services).some(checked => checked) || isOtherChecked;

        if (isServices && !checkedServices && !isContact) {
            setError("Please select at least one service.");
            setIsLoading(false);
            return;
        }
        await emailjs.sendForm('service_r5cnkbq', templateID, form.current, '_xj0McCsd3y-PAlam')
            .then((result) => {
                console.log(result.text);
                onEmailSent();
                onError();
                formContainerRef.current.scrollIntoView({ behavior: "smooth" });
            }, (error) => {
                onError(error.text);
            });
        setIsLoading(false);
    };

     console.log(templateID)

    return (
        <>
        <form
            className="form"
            ref={form}
            onSubmit={sendEmail}>
            {!isContact && (
            <>
                <div className='form__items-group'>
                    <div className="form__item">
                        <label htmlFor="property_type">{t('form.property-type')}*</label>
                        <select name="property_type" id="property_type" required>
                            <option value="Apartment">{t('form.apartment')}</option>
                             <option value="Penthouse">{t('form.penthouse')}</option>
                            <option value="Townhouse">{t('form.townhouse')}</option>
                            <option value="Villa">{t('form.villa')}</option>
                        </select>
                        <span></span>
                    </div>
                    <div className="form__item">
                        <label htmlFor="number_of_rooms">{t('form.number-of-rooms')}*</label>
                        <input
                            type="number"
                            placeholder="E.g. 3"
                            name="number_of_rooms"
                            id="number_of_rooms"
                            min="1"
                            max="20"
                            required />
                    </div>
                </div>
                <div className="form__item">
                    <label htmlFor="location">{t('form.location')}*</label>
                    <select name="location" id="location" required value={selectedOption} onChange={handleSelectChange}>
                        <option value="Benahavis">Benahavis</option>
                        <option value="Cabopino">Cabopino</option>
                        <option value="Calahonda">Calahonda</option>
                        <option value="Estepona">Estepona</option>
                        <option value="Marbella">Marbella</option>
                        <option value="Rivieradelsol">Riviera Del Sol</option>
                        <option value="Sanpedro">San Pedro</option>
                        <option value="Sotogrande">Sotogrande</option>
                        <option value="Other">{t('form.other')}</option>
                    </select>
                    <span></span>
                </div>
                {otherInputVisible && (
                    <div className="form__item">
                        <input 
                            type="text" 
                            placeholder="Specify your location"
                            id="other_location" 
                            name="other_location"
                            value={otherInputValue} 
                            onChange={handleOtherInputChange} />
                    </div>
                )}
                {isServices && (
                    <div className="form__item">
                    <fieldset required>
                        <legend>{t('form.services')}*</legend>
                        {error && <p className="error">{error}</p>}
                        <div>
                            <input 
                                type="checkbox" 
                                name="services_1" 
                                value="Key Holding" 
                                checked={services.services_1}
                                onChange={handleServicesCheckboxChange} />
                            <label htmlFor="services_1">{t('form.services-1')}</label>
                        </div>
                        <div>
                            <input 
                                type="checkbox" 
                                name="services_2" 
                                value="General Maintenance"
                                checked={services.services_2}
                                onChange={handleServicesCheckboxChange} />
                            <label htmlFor="services_2">{t('form.services-2')}</label>
                        </div>
                        <div>
                            <input 
                                type="checkbox" 
                                name="services_3" 
                                value="Routine Repairs Arrangement" 
                                checked={services.services_3}
                                onChange={handleServicesCheckboxChange} />
                            <label htmlFor="services_3">{t('form.services-3')}</label>
                        </div>
                        <div>
                            <input 
                                type="checkbox"
                                name="services_4" 
                                value="24/7 Emergency Contact" 
                                checked={services.services_4}
                                onChange={handleServicesCheckboxChange} />
                            <label htmlFor="services_4">{t('form.services-4')}</label>
                        </div>
                        <div>
                            <input 
                                type="checkbox" 
                                name="services_5" 
                                value="Housekeeping Arrangements" 
                                checked={services.services_5}
                                onChange={handleServicesCheckboxChange} />
                            <label htmlFor="services_5">{t('form.services-5')}</label>
                        </div>
                        <div>
                            <input 
                                type="checkbox" 
                                name="services_6" 
                                value="House and Electrical Appliances Check" 
                                checked={services.services_6}
                                onChange={handleServicesCheckboxChange} />
                            <label htmlFor="services_6">{t('form.services-6')}</label>
                        </div>
                        <div>
                            <input 
                                type="checkbox" 
                                name="services_7" 
                                value="House Check in Extreme Weather Conditions" 
                                checked={services.services_7}
                                onChange={handleServicesCheckboxChange}/>
                            <label htmlFor="services_7">{t('form.services-7')}</label>
                        </div>
                        <div>
                            <input 
                                type="checkbox" 
                                name="services_other" 
                                value="Other" 
                                checked={isOtherChecked}
                                onChange={handleCheckboxChange} 
                                />
                            <label htmlFor="services_other">{t('form.other')}</label>
                        </div>
                        {isOtherChecked && (
                            <input type="text" name="services_other_text" placeholder="Type other services here" required />
                        )}
                    </fieldset>
                    </div>
                )}
            </>
            )}
            <div className="form__items-group">
                <div className="form__item">
                    <label htmlFor="user_name">{t('form.name')}*</label>
                    <input
                        type="text"
                        placeholder={t('form.name-placeholder')}
                        name="user_name"
                        id="user_name"
                        required />
                </div>
                <div className="form__item">
                    <label htmlFor="user_email">{t('form.email')}*</label>
                    <input
                        type="email"
                        placeholder="Email"
                        name="user_email"
                        id="user_email"
                        required />
                </div>
            </div>
            <div className="form__items-group">
                <div className="form__item">
                    <label htmlFor="user_tel">{t('form.contact-number')}</label>
                    <PhoneInput
                        placeholder="Phone number"
                        name="user_tel"
                        id="user_tel"
                        defaultCountry="ES"
                        international={true}
                        value={value}
                        onChange={setValue} />
                </div>
                <div className="form__item">
                    <label htmlFor="language">{t('form.language-preference')}</label>
                    <select name="language" id="language">
                        <option value="english">English</option>
                        <option value="spanish">Español</option>
                        <option value="dutch">Nederlands</option>
                        <option value="russian">Русский</option>
                    </select>
                    <span></span>
                </div>
            </div>
            <div className="form__item">
                    <label htmlFor="message">{isContact ? `${t('form.message')}*` : `${t('form.additional-message')}`}</label>
                <textarea
                    placeholder={t('form.message-placeholder')}
                    name="message"
                    id="message"
                    rows="8" 
                    maxLength="500"
                    {...(isContact ? { required: true } : {})}
                    />
            </div>
            <div className="form__confirmation">
                <input
                    type="checkbox"
                    name="confirmation"
                    value="Confirmation"
                    required
                    />
                <label htmlFor="confirmation">
                    {t('form.confirmation-1')}
                        <Link to="/privacy-policy">{t('form.privacy-policy')}</Link> {t('form.confirmation-2')} <Link to="/legal-notice">{t('form.legal-notice')}</Link>.
                </label>
            </div>
            {!isLoading && <button
                className="cta"
                type="submit">
                {t('form.cta')}
                <svg width="30" height="15" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.6914 8.19862C30.0819 7.8081 30.0819 7.17493 29.6914 6.78441L23.3274 0.420448C22.9369 0.029924 22.3037 0.029924 21.9132 0.420448C21.5227 0.810973 21.5227 1.44414 21.9132 1.83466L27.5701 7.49152L21.9132 13.1484C21.5227 13.5389 21.5227 14.1721 21.9132 14.5626C22.3037 14.9531 22.9369 14.9531 23.3274 14.5626L29.6914 8.19862ZM0.171387 8.49152L28.9843 8.49152V6.49152L0.171387 6.49152L0.171387 8.49152Z" fill="white" />
                </svg>
            </button>}
            {isLoading &&
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            }
        </form>
        </>
    )
}

export default EmailForm;