import { Outlet, Link } from "react-router-dom";
import { useState } from 'react';
import './Layout.scss';
import { useTranslation } from "react-i18next";
import {LanguageSelector} from "./../../components/LanguageSelector/LanguageSelector";

const Layout = () => {
    const [toggleServices, setToggleServices] = useState(false)
    const [toggleMobileMenu, setToggleMobileMenu] = useState(false)
    const { t } = useTranslation("common");

    return (
        <>
        <div className="container container--header">
            <div className=" flex header">
                    <Link className="flex flex__col--5 logo" to="/" onClick={() => setToggleMobileMenu(!toggleMobileMenu)}>
                Premavista
                    {/* <img src={logo} /> */}
                </Link>
                <nav className="flex flex__col--7">
                    <ul className={`flex ${toggleMobileMenu ? "menu-open" : "menu-closed"}`}>
                        <li>
                            <Link to="/about" onClick={() => setToggleMobileMenu(!toggleMobileMenu)}>{t('app.menu.about')}</Link>
                            <div className="line"></div>
                        </li>
                        <li>
                            <button onClick={() => setToggleServices(!toggleServices)}>{t('app.menu.services')}<i className="chevron"></i></button>
                            <ul className={toggleServices ? "open" : "closed"}>
                                    <li>
                                        <Link to="/rental-management" onClick={() => { setToggleServices(!toggleServices); setToggleMobileMenu(!toggleMobileMenu) }}>{t('app.menu.rental-management')}</Link>
                                        <div className="line"></div>
                                    </li>
                                <li>
                                    <Link to="/property-management" onClick={() => {setToggleServices(!toggleServices); setToggleMobileMenu(!toggleMobileMenu)}}>{t('app.menu.property-management')}</Link>
                                    <div className="line"></div>
                                </li>
                                
                            </ul>
                        </li>
                        <li>
                            <Link to="/our-properties" onClick={() => setToggleMobileMenu(!toggleMobileMenu)}>{t('app.menu.our-properties')}</Link>
                            <div className="line"></div>
                        </li>
                        <li>
                            <Link to="/contact-us" onClick={() => setToggleMobileMenu(!toggleMobileMenu)}>{t('app.menu.contact-us')}</Link>
                            <div className="line"></div>
                        </li>   
                    </ul>    
                </nav>
                <LanguageSelector className="language-selector" />
                <button className={`burger-menu ${toggleMobileMenu ? "open" : ""}`} onClick={() => setToggleMobileMenu(!toggleMobileMenu)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div> </div>
            <Outlet />
       
        </>
    )
};

export default Layout;