import { useState } from 'react';
import { useTranslation } from "react-i18next";
import './LanguageSelector.scss'

export const LanguageSelector = () => {
    const [toggle, setToggle] = useState(false)
    const [t, i18n] = useTranslation('common');
    return (
        <div className='language-selector'>
            <button className='language-selector__btn' onClick={() => setToggle(!toggle)}>
                <svg width="18" height="18" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.4001 13C17.4001 16.4113 16.742 19.4685 15.7061 21.6495C14.6522 23.8682 13.3073 25 12.0001 25C10.6929 25 9.34801 23.8682 8.29411 21.6495C7.25817 19.4685 6.6001 16.4113 6.6001 13C6.6001 9.58868 7.25817 6.53152 8.29411 4.35058C9.34801 2.13185 10.6929 1 12.0001 1C13.3073 1 14.6522 2.13185 15.7061 4.35058C16.742 6.53152 17.4001 9.58868 17.4001 13Z" stroke="black" />
                    <path d="M23.4001 13C23.4001 19.6274 18.2961 25 12.0001 25C5.70405 25 0.600098 19.6274 0.600098 13C0.600098 6.37258 5.70405 1 12.0001 1C18.2961 1 23.4001 6.37258 23.4001 13Z" stroke="black" />
                    <path d="M0 13H24" stroke="black" />
                </svg>
                {i18n.language}
            </button>
            <ul className={toggle ? "open" : "closed"}>
                <li>
                    <button onClick={() => {i18n.changeLanguage('en'); setToggle(!toggle)}}>{t('language-selector.languages.en')}</button>
                    <div className="line"></div>
                </li>
                <li>
                    <button onClick={() => {i18n.changeLanguage('es'); setToggle(!toggle)}}>{t('language-selector.languages.es')}</button>
                    <div className="line"></div>
                </li>
                <li>
                    <button onClick={() => {i18n.changeLanguage('nl'); setToggle(!toggle) }}>{t('language-selector.languages.nl')}</button>
                    <div className="line"></div>
                </li>
                <li>
                    <button onClick={() => {i18n.changeLanguage('ru'); setToggle(!toggle)}}>{t('language-selector.languages.ru')}</button>
                    <div className="line"></div>
                </li>
            </ul>
        </div>
    )
}
