import { useTranslation } from "react-i18next";
import hero from "./../../static/images/home/hero.jpg";
import imgGrid1 from "./../../static/images/home/img-grid-1.jpg";
import imgGrid2 from "./../../static/images/home/img-grid-2.jpg";
import imgGrid3 from "./../../static/images/home/img-quote.jpg";
import Hero from "../../components/Hero/Hero";
import CTABlock from "../../components/CTABlock/CTABlock";
import Incentive from "../../components/Incentive/Incentive";
import Grid from "../../components/Grid/Grid";
import Carousel from "../../components/Carousel/Carousel";
import useHomeCarouselProperties from "../../static/content/HomeContent/HomeCarouselProperties";
import useHomeCarouselReviews from "../../static/content/HomeContent/HomeCarouselReviews";
import Highlights from "../../components/Highlights/Highlights";
import useHomeHighlights from "../../static/content/HomeContent/HomeHighlights";
import DocumentMeta from "../../components/DocumentMeta/DocumentMeta";

const Home = () => {
    DocumentMeta("Premavista - Short Term Rental and Property Management Services in Marbella", "Premavista offers professional short-term rental and property management services in Marbella. Maximize your rental income with our hassle-free solutions for Airbnb, Booking.com, and more.")
    const { t } = useTranslation("common");

    const slidesCarouselProperties = useHomeCarouselProperties();
    const slidesCarouselReviews = useHomeCarouselReviews();
    const highlights = useHomeHighlights();
    
    return (
        <>
            <Hero heroImg={hero} heading1={t('home.h1')}/>
            <CTABlock heading2={t('home.ctablock-1-h2')} text={t('home.ctablock-1-text')} ctaText={t('home.ctablock-1-ctatext')} ctaHref='/contact-us'/>
            <Grid reverse={true} imgSrc={imgGrid2} imgAlt={t('home.grid-2-img-alt')} heading3={t('home.grid-2-h3')} text={t('home.grid-2-text')} ctaText={t('home.grid-2-ctatext')} ctaHref='rental-management' />
            <Grid imgSrc={imgGrid1} imgAlt={t('home.grid-1-img-alt')} heading3={t('home.grid-1-h3')} text={t('home.grid-1-text')} ctaText={t('home.grid-1-ctatext')} ctaHref='property-management' />
            <Grid reverse={true} background={true} imgSrc={imgGrid3} imgAlt={t('home.grid-3-img-alt')} heading3={t('home.grid-3-h3')} text={t('home.grid-3-text')} ctaText={t('home.grid-3-ctatext')} ctaHref='about' />
            <Incentive heading2={t('home.incentive-1-h2')} incentiveCTAText={t('home.incentive-1-ctatext')} incentiveCTAHref='/contact-us' />
            <Carousel reviews={true} heading2={t('home.carousel-reviews-h2')} slidesToShow={2} slides={slidesCarouselReviews} />
            <Highlights heading2={t('home.highlights-h2')} highlights={highlights} />
            <Carousel heading2={t('home.carousel-properties-h2')} slidesToShow={1} slides={slidesCarouselProperties} ctaText={t('home.carousel-properties-ctatext')} ctaHref='/our-properties'/>
            <Incentive heading2={t('home.incentive-2-h2')} incentiveCTAText={t('home.incentive-2-ctatext')} incentiveCTAHref='/contact-us'/>
        </>
    )
}

export default Home;