import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation("common");

    return (
        <div className="container">
        <Trans>
           <h1>{t('privacy-policy.h1')}</h1> 
           <h3>{t('privacy-policy.1-h3')}</h3> 
           <p>{t('privacy-policy.1-p-1')}</p>
           <p>{t('privacy-policy.1-p-2')}</p>
           <h3>{t('privacy-policy.2-h3')}</h3>
           <p>{t('privacy-policy.2-p-1')}</p>
           <h3>{t('privacy-policy.3-h3')}</h3>
           <p>{t('privacy-policy.3-p-1')}</p>
           <h3>{t('privacy-policy.4-h3')}</h3>
           <p>{t('privacy-policy.4-p-1')}</p>
           <h3>{t('privacy-policy.5-h3')}</h3>
           <p>{t('privacy-policy.5-p-1')}</p>
           <h3>{t('privacy-policy.6-h3')}</h3>
           <p>{t('privacy-policy.6-p-1')}</p>
           <h3>{t('privacy-policy.7-h3')}</h3>
           <p>{t('privacy-policy.7-p-1')}</p>
           <h3>{t('privacy-policy.8-h3')}</h3>
           <p>{t('privacy-policy.8-p-1')}</p>
        </Trans>
        </div>
    )
}

export default PrivacyPolicy;