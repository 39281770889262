import { useTranslation } from "react-i18next";
import Hero from "../../components/Hero/Hero";
import hero from "./../../static/images/about/hero.jpg";
import CTABlock from "../../components/CTABlock/CTABlock";
import useAboutTestimonials from "../../static/content/AboutContent/AboutTestimonials";
import Testimonials from "../../components/Testimonials/Testimonials";
import GridWrapper from "../../components/GridWrapper/GridWrapper";
import Grid from "../../components/Grid/Grid";
import value1 from "./../../static/images/about/values-1.jpg";
import value2 from "./../../static/images/about/values-2.jpg";
import value3 from "./../../static/images/about/values-3.jpg";
import Incentive from "../../components/Incentive/Incentive";
import DocumentMeta from "../../components/DocumentMeta/DocumentMeta";

const About = () => {
    DocumentMeta("About Us - Premavista", "Learn more about Premavista, a leading provider of short-term rental and property management services in Marbella. Our team of experts is dedicated to maximizing your rental income and providing exceptional property care.")
    const { t } = useTranslation("common");

    const testimonials = useAboutTestimonials();

    return (
        <>
            <Hero heroImg={hero} heading1={t('about.h1')} />
            <CTABlock heading2={t('about.ctablock-1-h2')} text={t('about.ctablock-1-text')} />
            <Testimonials heading2={t('about.testimonials-h2')} testimonials={testimonials} />
            <GridWrapper>
                <Grid heading2={t('about.values-1-h2')} imgSrc={value1} imgAlt={t('about.values-1-img-alt')} href={t('about.values-1-href')} linkText={t('about.values-1-link-text')} text={t('about.values-1-text')} />
                <Grid heading2={t('about.values-2-h2')} imgSrc={value2} imgAlt={t('about.values-2-img-alt')} href={t('about.values-2-href')} linkText={t('about.values-2-link-text')} text={t('about.values-2-text')} />
                <Grid heading2={t('about.values-3-h2')} imgSrc={value3} imgAlt={t('about.values-3-img-alt')} href={t('about.values-3-href')} linkText={t('about.values-3-link-text')} text={t('about.values-3-text')} />
            </GridWrapper>
            <Incentive heading2={t('about.incentive-1-h2')} incentiveCTAText={t('about.incentive-1-ctatext')} incentiveCTAHref='/contact-us' />
        </>
    )
}

export default About;