import { useTranslation } from "react-i18next";

const usePropertyManagementAccordionFAQs = () => {
    const { t } = useTranslation("common");
    const accordionItems = [
        {
            title: t('property-management.accordion-2-item-1-title'),
            text: t('property-management.accordion-2-item-1-text')
        },
        {
            title: t('property-management.accordion-2-item-2-title'),
            text: t('property-management.accordion-2-item-2-text')
        },
        {
            title: t('property-management.accordion-2-item-3-title'),
            text: t('property-management.accordion-2-item-3-text')
        },
        {
            title: t('property-management.accordion-2-item-4-title'),
            text: t('property-management.accordion-2-item-4-text')
        },
        {
            title: t('property-management.accordion-2-item-5-title'),
            text: t('property-management.accordion-2-item-5-text')
        },
        {
            title: t('property-management.accordion-2-item-6-title'),
            text: t('property-management.accordion-2-item-6-text')
        },
    ]

    return accordionItems
}

export default usePropertyManagementAccordionFAQs;