import './Hero.scss';
import { Trans } from 'react-i18next';

const Hero = ({heroImg, heading1}) => {
    return (
        <div className="hero flex" style={{backgroundImage: `url(${heroImg})`}}>
            <div className="hero__background"></div>
            <div className="container">
                <Trans>
                    <h1>{heading1}</h1>
                </Trans>
            </div>
        </div>
    )
}

export default Hero;