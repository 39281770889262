import './ContactUs.scss';
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import Hero from "../../components/Hero/Hero";
import hero from "./../../static/images/contact-us/hero.jpeg";
import hero1 from "./../../static/images/contact-us/hero1.jpg";
import FormContainer from '../../components/FormContainer/FormContainer';
import gb from "./../../static/svg/gb.svg";
import es from "./../../static/svg/es.svg";
import nl from "./../../static/svg/nl.svg";
import ru from "./../../static/svg/ru.svg";
import DocumentMeta from "../../components/DocumentMeta/DocumentMeta";

const ContactUs = () => {
    DocumentMeta("Contact Us - Premavista", "Contact Us - Premavista")
    const { t } = useTranslation("common");

    const [showEstimate1, setShowEstimate1] = useState(false);
    const [showEstimate2, setShowEstimate2] = useState(false);

    const handleButtonClick1 = () => {
        setShowEstimate1(true);
        setShowEstimate2(false); // Hide the other element if necessary
    };

    const handleButtonClick2 = () => {
        setShowEstimate2(true);
        setShowEstimate1(false); // Hide the other element if necessary
    };
    return (
        <div className="contact-us__page">
            <Hero heroImg={hero} heading1={t('contact-us.h1')} />
            <FormContainer heading3={t('contact-us.form-h3')} contact={true} templateID='template_nu793zl' />
            <div className="background">
                <div className="contact-us container flex">
                    <div className="contact-us__text flex flex__col--4">
                    <h2>{t('contact-us.grid-h2')}</h2>
                    <h3>Email</h3>
                    <div className="link">
                        <a href="mailto:info@premavista.com">info@premavista.com</a>
                        <div className="line"></div>
                    </div>
                    <h3>{t('contact-us.grid-h3-1')}</h3>
                    <div className="contact-us__phone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" /></svg>
                        <div className="link">
                            <a aria-label="Chat on WhatsApp" href="https://wa.me/34603428362">
                                +34 603 428 362
                            </a>
                            <div className="line"></div>
                        </div>
                        <img src={gb} alt="UK flag" />
                        <img src={ru} alt="Russia flag" />
                    </div>
                    <div className="contact-us__phone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" /></svg>
                        <div className="link">
                            <a aria-label="Chat on WhatsApp" href="https://wa.me/34600543173">
                                +34 600 543 173
                            </a>
                            <div className="line"></div>
                        </div>
                        <img src={gb} alt="UK flag" />
                        <img src={es} alt="Spain flag" />
                        <img src={nl} alt="Netherlands flag" />
                    </div>
                    <h3>{t('contact-us.grid-h3-2')}</h3>
                    <div className="contact-us__socials">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg>
                        <div className="link">            
                            <a href="/">Instagram</a>
                            <div className="line"></div>
                        </div>
                    </div>
                    <div className="contact-us__socials">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" /></svg>    
                        <div className="link">
                            <a href="/">Facebook</a>
                            <div className="line"></div>
                        </div>
                    </div>
                    </div>
                    <div className="contact-us__img flex flex__col--8">
                        <img src={hero1} alt="Owners of Premavista working together at the table" />
                    </div>
                </div>
            </div>
            <div className="contact-us__ctas container flex">
                <button className="cta" onClick={handleButtonClick1}>
                    {t('contact-us.cta-1')}
                    <svg width="30" height="15" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.6914 8.19862C30.0819 7.8081 30.0819 7.17493 29.6914 6.78441L23.3274 0.420448C22.9369 0.029924 22.3037 0.029924 21.9132 0.420448C21.5227 0.810973 21.5227 1.44414 21.9132 1.83466L27.5701 7.49152L21.9132 13.1484C21.5227 13.5389 21.5227 14.1721 21.9132 14.5626C22.3037 14.9531 22.9369 14.9531 23.3274 14.5626L29.6914 8.19862ZM0.171387 8.49152L28.9843 8.49152V6.49152L0.171387 6.49152L0.171387 8.49152Z" fill="white" />
                    </svg>
                </button>
                <button className="cta" onClick={handleButtonClick2}>
                    {t('contact-us.cta-2')}
                    <svg width="30" height="15" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.6914 8.19862C30.0819 7.8081 30.0819 7.17493 29.6914 6.78441L23.3274 0.420448C22.9369 0.029924 22.3037 0.029924 21.9132 0.420448C21.5227 0.810973 21.5227 1.44414 21.9132 1.83466L27.5701 7.49152L21.9132 13.1484C21.5227 13.5389 21.5227 14.1721 21.9132 14.5626C22.3037 14.9531 22.9369 14.9531 23.3274 14.5626L29.6914 8.19862ZM0.171387 8.49152L28.9843 8.49152V6.49152L0.171387 6.49152L0.171387 8.49152Z" fill="white" />
                    </svg>
                </button>
            </div>
            {showEstimate1 && (
                <FormContainer heading3={t('rental-management.form-h3')} templateID='template_f3eob18' />
            )}
            {showEstimate2 && (
                <FormContainer heading3={t('property-management.form-h3')} templateID='template_f3eob18' services={true} />
            )}
        </div>
    )
}

export default ContactUs;