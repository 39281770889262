import './Accordion.scss';
import { useState } from 'react';

const Accordion = ({ heading2, accordionItems }) => {
    const NO_INDEX = -1;
    const [activeIndex, setActiveIndex] = useState(NO_INDEX);

    const onClick = (index) => {
        if (index === activeIndex) {
            setActiveIndex(NO_INDEX);
        } else {
            setActiveIndex(index)
        }
    }

    return (
        <div className="container container--accordion">
            {heading2 && <h2 className="align-center">{heading2}</h2>}
            <div className="accordion">
                {accordionItems.map((item, index) => {
                    const isActive = index === activeIndex

                    return (
                        <div key={index} className={`accordion__item ${isActive ? "open" : ""}`} >
                            <button className={`accordion__btn ${isActive ? "accordion__btn--open" : ""}`} onClick={() => onClick(index)}>
                                <span>{item.title}</span>
                            </button>
                            {isActive && (
                                <p className="accordion__text">{item.text}</p>
                            )}
                        </div>
                    )
                }
                )}
            </div>
        </div>
    )
}

export default Accordion;