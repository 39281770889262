import './Testimonials.scss';

const Testimonials = ({ heading2, testimonials }) => {
    const testimonialsMap = testimonials.map((testimonial) =>
        <div className="testimonial flex">
            <div className="testimonial__img">
                <img src={testimonial.imgSrc} alt={testimonial.imgAlt} />
                <p className="testimonial__author">{testimonial.authorName}</p>
                <p className="testimonial__role">{testimonial.authorRole}</p>
            </div>
            <div className="testimonial__text">
                <p>"{testimonial.text}"</p>
            </div>
            
        </div>
    )

    return (
        <div className="container">
            <h2>{heading2}</h2>
            <div className="testimonials flex">
                {testimonialsMap}
            </div>
        </div>
    )
}

export default Testimonials;