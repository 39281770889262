import './GridWrapper.scss';

const GridWrapper = ({ children }) => {
    return (
        <div className='grid-wrapper'>
            <div className="container">
                {children}
            </div>
        </div>
    )
}

export default GridWrapper;