import { Link } from "react-router-dom";
import './CTA.scss';

const CTA = ({ ctaText, ctaHref }) => {
    return (
        <Link className="cta" to={ctaHref}>
            {ctaText}
            <svg width="30" height="15" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.6914 8.19862C30.0819 7.8081 30.0819 7.17493 29.6914 6.78441L23.3274 0.420448C22.9369 0.029924 22.3037 0.029924 21.9132 0.420448C21.5227 0.810973 21.5227 1.44414 21.9132 1.83466L27.5701 7.49152L21.9132 13.1484C21.5227 13.5389 21.5227 14.1721 21.9132 14.5626C22.3037 14.9531 22.9369 14.9531 23.3274 14.5626L29.6914 8.19862ZM0.171387 8.49152L28.9843 8.49152V6.49152L0.171387 6.49152L0.171387 8.49152Z" fill="white" />
            </svg>
        </Link>
    )
}

export default CTA;