import {useEffect} from 'react'

function DocumentMeta(title, description) {

    useEffect(() => {
        document.title = title;
        const metaDescription = document.querySelector("meta[name='description']");
        metaDescription.setAttribute('content', description);
    }, [title, description]);
}
export default DocumentMeta