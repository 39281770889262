import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// This component doesn't render anything, it just handles scrolling
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export default ScrollToTop;