import { useTranslation } from "react-i18next";
import bookingLogo from './../../images/home/booking-logo.png';
import airbnbLogo from './../../images/home/airbnb-logo.png';
import vrboLogo from './../../images/home/vrbo-logo.png';

const useHomeCarouselReviews = () => {
    const { t } = useTranslation("common");
    const slides = [
        {
            imgSrc: airbnbLogo,
            imgAlt: t('home.carousel-reviews-slide-2-imgAlt'),
            reviewText: t('home.carousel-reviews-slide-2-reviewText'),
            reviewAuthor: t('home.carousel-reviews-slide-2-reviewAuthor')
        },
        {
            imgSrc: airbnbLogo,
            imgAlt: t('home.carousel-reviews-slide-1-imgAlt'),
            reviewText: t('home.carousel-reviews-slide-1-reviewText'),
            reviewAuthor: t('home.carousel-reviews-slide-1-reviewAuthor')
        },
        {
            imgSrc: airbnbLogo,
            imgAlt: t('home.carousel-reviews-slide-3-imgAlt'),
            reviewText: t('home.carousel-reviews-slide-3-reviewText'),
            reviewAuthor: t('home.carousel-reviews-slide-3-reviewAuthor')
        },
        {
            imgSrc: airbnbLogo,
            imgAlt: t('home.carousel-reviews-slide-4-imgAlt'),
            reviewText: t('home.carousel-reviews-slide-4-reviewText'),
            reviewAuthor: t('home.carousel-reviews-slide-4-reviewAuthor')
        },
        {
            imgSrc: bookingLogo,
            imgAlt: t('home.carousel-reviews-slide-6-imgAlt'),
            reviewText: t('home.carousel-reviews-slide-6-reviewText'),
            reviewAuthor: t('home.carousel-reviews-slide-6-reviewAuthor')
        },
        {
            imgSrc: bookingLogo,
            imgAlt: t('home.carousel-reviews-slide-5-imgAlt'),
            reviewText: t('home.carousel-reviews-slide-5-reviewText'),
            reviewAuthor: t('home.carousel-reviews-slide-5-reviewAuthor')
        },
        {
            imgSrc: vrboLogo,
            imgAlt: t('home.carousel-reviews-slide-7-imgAlt'),
            reviewText: t('home.carousel-reviews-slide-7-reviewText'),
            reviewAuthor: t('home.carousel-reviews-slide-7-reviewAuthor')
        },
    ]

    return slides
}

export default useHomeCarouselReviews;