import { useTranslation } from "react-i18next";
import Hero from "../../components/Hero/Hero";
import hero from "./../../static/images/our-properties/hero.jpg";
import DocumentMeta from "../../components/DocumentMeta/DocumentMeta";

const OurProperties = () => {
    DocumentMeta("Our Properties - Premavista", "Our Properties - Premavista")
    const { t } = useTranslation("common");

    return (
        <>
            <Hero heroImg={hero} heading1={t('our-properties.h1')} />
            <div className="container">
                <h2 style={{textAlign: "center", margin: "200px 0"}}>Coming soon</h2>
            </div>
        </>
    )
}

export default OurProperties;